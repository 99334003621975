<template>
  <div>
    <div
      class="md:bg-top md:bg-no-repeat md:bg-cover xl:bg-bottom"
      style="background-image: url('/images/main-banner.jpg')"
    >
      <header
        v-if="!hideEnquiry"
        class="fixed top-0 left-0 w-full py-5 transition duration-200 ease-linear lg:py-5"
        :class="fixtop ? ' bg-white shadow-md z-10' : 'bg-white'"
      >
        <div class="container">
          <div class="flex items-center justify-between text-white">
            <img
              src="/images/main-logo.png"
              alt=""
              class="w-44 md:w-52 lg:w-60"
            />
            <div>
              <div class="flex text-gray-1">
                <a
                  href="tel:+60162112628"
                  class="px-2 transition duration-200 ease-linear transform md:inline-flex md:items-center hover:scale-110"
                >
                  <img src="/images/icon-call.png" alt="" class="w-8 md:w-6" />
                  <p class="hidden pl-3 text-xl font-bold md:block">
                    016-211 2628
                  </p>
                </a>
                <a
                  href="https://wa.me/60162112628"
                  class="px-2 transition duration-200 ease-linear transform md:inline-flex md:items-center hover:scale-110"
                >
                  <img
                    src="/images/icon-whatsapp.png"
                    alt=""
                    class="w-8 md:w-6"
                  />
                  <p class="hidden pl-3 text-xl font-bold md:block">
                    Whatsapp Us
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <!-- banner tagline -->
      <!-- <div class=""></div> -->
      <div class="">
        <div class="h-16 bg-white"></div>
        <img src="/images/main-banner.jpg" alt="" class="w-full" />
      </div>
      <!-- <div class="bgSize"></div> -->
    </div>
    <div class="bg-gray-1">
      <div class="container">
        <div class="py-20 lg:max-w-3xl lg:mx-auto">
          <div class="text-center text-white">
            <h1 class="text-2xl font-bold leading-tight md:text-3xl">
              We are dedicated to uplift forklift quality. To become the most
              reliable and reputable forklift multiservice center in Malaysia
            </h1>
            <div class="w-auto h-0.5 bg-yellow-400 my-3"></div>
            <p class="text-base font-semibold leading-tight lg:text-lg">
              Specialised in sales and rental of new & reconditioned forklifts,
              parts and services of material handling equipment
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fixtop: false,
    };
  },
  methods: {
    scrollListener: function (e) {
      this.fixtop = window.scrollY > 150;
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>
<style>
.bgSize {
  min-height: 400px;
}

@media (min-width: 768px) {
  .bgSize {
    min-height: 600px;
  }
}
@media (min-width: 1280px) {
  .bgSize {
    min-height: 800px;
  }
}
</style>
