<template>
  <div>
    <WaBtn />
    <!-- header -->
    <MainBanner />

    <!-- services -->
    <div class="py-10">
      <div class="container">
        <div class="text-2xl font-bold text-center text-gray-1">
          <p>Our Speciality</p>
          <div class="bg-red-1 mt-3 w-10 h-0.5 mx-auto"></div>
        </div>

        <div class="mt-6 md:flex md:flex-wrap">
          <div
            v-for="(item, i) in services"
            :key="i"
            class="pb-5 md:w-1/2 lg:w-1/4 md:p-3"
          >
            <img :src="'/images/' + item.image + '.jpg'" :alt="item.alt" />
            <div class="pt-4 text-center">
              <p class="text-xl font-bold">{{ item.alt }}</p>
              <p
                class="text-lg font-semibold text-red-1 lg:leading-tight lg:h-14"
              >
                {{ item.p1 }}
              </p>
              <p class="text-lg font-semibold lg:leading-tight lg:h-14">
                {{ item.p2 }}
              </p>
            </div>
            <div class="pt-4">
              <p
                class="py-1 mx-auto text-lg font-semibold text-center text-white transition duration-200 ease-linear transform rounded-md bg-red-1 hover:scale-110 w-44"
              >
                <a href="#" v-scroll-to="{ el: '#enquiry', duration: 1500 }">
                  Enquire Now
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- sub banner -->
    <div class="py-10 md:py-20 bg-red-1">
      <div class="container lg:max-w-4xl">
        <div class="text-center text-white">
          <h1 class="text-2xl font-bold leading-tight md:text-3xl lg:text-4xl">
            "Established since 2002 with more than 20 years of history"
          </h1>
          <p class="pt-4 text-base font-semibold leading-tight lg:text-lg">
            Specialised in sales and rental of new & reconditioned forklifts,
            parts and services of material handling equipment
          </p>
        </div>
        <div class="pt-4">
          <p
            class="py-1 mx-auto text-lg font-semibold text-center text-gray-700 bg-yellow-400 rounded-md w-44"
          >
            <a href="#" v-scroll-to="{ el: '#enquiry', duration: 1500 }"
              >Enquire Now</a
            >
          </p>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div
      class="pt-10 lg:py-10 lg:bg-cover lg:bg-left lg:bg-no-repeat"
      style="background-image: url('/images/bg-choose-desk.jpg')"
    >
      <div class="container">
        <div class="lg:w-1/2">
          <div class="text-2xl font-bold text-center lg:text-left text-gray-1">
            <p>Why Choose Us</p>
            <div class="bg-red-1 mt-3 lg:mx-0 w-10 h-0.5 mx-auto"></div>
          </div>
          <p
            class="pt-5 font-medium leading-tight text-center text-gray-600 lg:text-left"
          >
            When it comes to choosing a reliable and efficient forklift for your
            business needs, look no further than our exceptional range of
            forklifts. We take immense pride in providing top-quality equipment
            that delivers exceptional performance, durability, and safety.
            Here's why choosing us as your forklift provider is the best
            decision you can make.
          </p>
          <!-- list -->
          <div class="mt-6">
            <div
              v-for="(item, i) in choose_us"
              :key="i"
              class="flex justify-center pb-5"
            >
              <div class="1/4">
                <img
                  :src="'/images/' + item.image + '.png'"
                  :alt="item.alt"
                  class="w-20 h-20 mx-auto"
                />
              </div>
              <div class="w-3/4 pl-3">
                <p class="text-lg font-bold">{{ item.alt }}</p>
                <p class="leading-tight">
                  {{ item.p }}
                </p>
              </div>
            </div>
          </div>
          <p
            class="pt-5 font-medium leading-tight text-center text-gray-600 lg:text-left"
          >
            At Multilift Equipment Sdn Bhd, we are committed to providing
            top-quality forklifts, unmatched service, and customer satisfaction.
            With our superior products, customization options, and comprehensive
            support, we are the ideal partner for all your material handling
            needs. Contact us today and experience the difference firsthand.
          </p>
        </div>
      </div>
      <div class="mt-5 lg:hidden">
        <img src="/images/bg-choose.jpg" alt="" class="" />
      </div>
    </div>

    <!-- key services -->
    <div class="py-10 bg-gray-100">
      <div class="container">
        <div class="text-2xl font-bold text-center text-gray-1">
          <p>Our Key Services</p>
          <div class="bg-red-1 mt-3 w-10 h-0.5 mx-auto"></div>
        </div>

        <div class="mt-7 md:flex md:flex-wrap xl:max-w-4xl xl:mx-auto">
          <div
            v-for="(item, i) in key_services"
            :key="i"
            class="pb-6 lg:transform lg:duration-300 hover:scale-105 md:w-1/2 md:px-1"
          >
            <div class="bg-white shadow-md">
              <img
                :src="'/images/' + item.image + '.jpg'"
                :alt="item.alt"
                class=""
              />
              <div class="p-5">
                <p class="text-2xl font-bold text-gray-1">{{ item.alt }}</p>
                <p class="text-lg font-semibold text-red-1">
                  {{ item.p }}
                </p>
                <div class="pt-4 space-y-1 font-medium text-gray-600">
                  <p v-for="list in item.p2" :key="list">{{ list }}</p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- enquiry section -->
    <div
      id="enquiry"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')"
    >
      <div class="container">
        <div class="text-center text-white lg:max-w-3xl lg:mx-auto">
          <p class="text-2xl font-bold leading-tight lg:text-3xl">
            Established since 2002 with more than 20 years of history
          </p>
          <p class="pt-4 text-base font-semibold leading-tight">
            Specialised in sales and rental of new & reconditioned forklifts,
            parts and services of material handling equipment
          </p>
        </div>
        <div class="pt-6 md:w-2/3 md:mx-auto">
          <EnquiryForm class="px-5 py-8 bg-white rounded" />
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="py-10 bg-white">
      <div class="container">
        <div>
          <img
            src="/images/main-logo.png"
            alt=""
            class="w-2/3 mx-auto md:w-60"
          />
          <p class="pt-5 text-xl font-semibold text-center text-gray-1">
            Address:
          </p>
          <div class="lg:pt-6 lg:flex lg:max-w-3xl lg:mx-auto">
            <div class="pb-4 leading-tight text-center">
              <p class="font-bold text-gray-1">HQ</p>
              <p class="font-bold text-gray-1">
                MULTILIFT EQUIPMENT SDN BHD <br />(596839-M)
              </p>
              <p class="pt-2">
                No. 3, Jln Sg Kapar Indah 3/KU7, Kawasan Industrian HI-Tech, Sg
                Kapar Indah <br />
                42200 Kapar, Selangor D.E.
              </p>
            </div>
            <div class="leading-tight text-center">
              <p class="font-bold uppercase text-gray-1">Showroom</p>
              <p class="font-bold text-gray-1">
                MULTILIFT MARKETING SDN BHD <br />
                (1116451-D)
              </p>
              <p class="pt-2">
                No 12A, Jln Sg Batu 11/KU6, Kawasan Perindustrian Sungai Puloh, <br>
                42100 Klang, Selangor D.E.
              </p>
            </div>
          </div>
          <div class="pt-6 text-xl text-center">
            <p class="font-semibold text-gray-1">
              Contact No:
              <a
                href="tel:+60162112628"
                class="font-normal transition duration-300 hover:text-red-1"
                >016-211 2628
              </a>
            </p>
          </div>
          <div class="pt-4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.965677012973!2d101.37827087534457!3d3.1037747968718006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc5510ee870b7b%3A0xd86da2c4dad761b8!2sMultilift%20Equipment%20Sdn.%20Bhd.!5e0!3m2!1sen!2smy!4v1690180892256!5m2!1sen!2smy"
              width="100%"
              height="350"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <section class="py-3 bg-gray-1">
      <div class="container mx-auto">
        <p class="text-sm font-medium text-center text-white">
          Website maintained by Activa Media. All Rights Reserved.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import MainBanner from "@/components/MainBanner.vue";
import EnquiryForm from "@/components/EnquiryForm.vue";
import WaBtn from "@/components/WaBtn.vue";

export default {
  name: "Home",
  components: {
    MainBanner,
    EnquiryForm,
    WaBtn,
  },
  data() {
    return {
      services: [
        {
          image: "service-1",
          alt: "BATTERY FORKLIFTS",
          p1: "SALES & RENTAL",
          p2: "FULLY RECONDITIONED",
        },
        {
          image: "service-2",
          alt: "ENGINE FORKLIFTS",
          p1: "SALES & RENTAL",
          p2: "NEW & FULLY RECONDITIONED",
        },
        {
          image: "service-3",
          alt: "FORKLIFTS RENTAL",
          p1: "NEW & FULLY RECONDITIONED",
          p2: "DELIVERY IN 24HRS",
        },
        {
          image: "service-4",
          alt: "REPAIR & SERVICING",
          p1: "FORKLIFT MAINTENANCE",
          p2: "CERTIFIED TECHNICIANS",
        },
      ],
      choose_us: [
        {
          image: "icon-1",
          alt: "Ready Stock",
          p: "Have more than 250 forklift units ready for immediate short- or long-term rent. ",
        },
        {
          image: "icon-2",
          alt: "Delivery in 24HR",
          p: "Helping you save time and resume to operation quickly. ",
        },
        {
          image: "icon-3",
          alt: "Reliable units",
          p: "Proven safety and reliability an experience team at your service.",
        },
        {
          image: "icon-4",
          alt: "Quality Assurance",
          p: "Our reconditioning process is carried out by a team of experienced professionals with in-depth knowledge of forklifts.",
        },
      ],
      key_services: [
        {
          image: "key-1",
          alt: "FORKLIFTS SALES",
          p: "FULLY RECONDITIONED",
          p2: [
            "Engine/Battery Type",
            "1 Year of A-Z Warranty",
            "Biggest Stockist",
          ],
        },
        {
          image: "key-2",
          alt: "FORKLIFTS RENTAL",
          p: "NEW & FULLY RECONDITIONED",
          p2: ["Short & Long Term", "Delivery in 24hrs", "Ready Stock"],
        },
      ],
    };
  },
};
</script>
