<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>
import WebFontLoader from "webfontloader";

export default {
  components: {
    WebFontLoader,
  },
  methods: {
    loadFonts() {
      WebFont.load({
        google: {
          families: ['Source+Sans+Pro:200,300,500,600,700']
        },
        classes: false,
        active: () => {
          this.fontsLoaded = true;
        },
      });
    },
  },
  mounted() {
    this.loadFonts();
  },
};
</script>
<style>
#app {
  font-family: 'Source Code Pro', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
